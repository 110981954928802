import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3760d1ce"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["placeholder", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("fieldset", {
      class: _normalizeClass(["InputTextarea", {'InputTextarea--Disabled':_ctx.disabled}])
    }, [
      _createElementVNode("legend", null, _toDisplayString(_ctx.label), 1),
      _withDirectives(_createElementVNode("textarea", {
        placeholder: _ctx.placeholder,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
        disabled: _ctx.disabled,
        onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('blur')))
      }, "\n        ", 40, _hoisted_1), [
        [_vModelText, _ctx.value]
      ])
    ], 2),
    _createElementVNode("label", null, _toDisplayString(_ctx.$t('suite_study_textarea',{max:_ctx.max})), 1)
  ]))
}